$zIndex: 11;

.modal-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: $zIndex;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);

    img {
        object-fit: contain;
        max-height: 80vh;
        max-width: 80vw;
    }

    &-card {
        position: relative;
        z-index: $zIndex;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
    }

    &-image {
        position: relative;
        z-index: $zIndex;

        &-left, &-right {
            height: calc(100% - 2rem - 4px);
            cursor: pointer;
            display: flex;
            align-items: center;
            color: white;
            font-weight: bold;
            padding: 1rem;
            font-size: 2rem;
            position: absolute;

            &:hover {
                background-color: rgba(255,255,255,0.1);
            }
        }

        &-left {
            left: 0;
        }
    
        &-right {
            right: 0;
        }
    }

    &-close {
        position: absolute;
        top: 2%;
        right: 2%;
        color: #e1e0e0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        line-height: 10px;
        align-items: center;
        cursor: pointer;
        background-color: rgba(142, 139, 136, .5);

        svg {
            font-size: 1.1rem;
        }

        &:hover {
            background-color: rgba(243, 243, 243, 0.4);
        }
    }
}

.modal-body {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 80vh;
    max-height: 80vh;
    max-width: 80vw;
    min-width: 300px;
    background-color: white;
    border-radius: 30px;
    padding: 20px;
    gap: 20px;


    &-top-content {
        padding: 0 24px;
    }

    &-title {
        display: grid;
        grid-template-columns: 1fr 30px;

        &-text {
            font-size: 1.75rem;
            font-weight: 700;
        }

        &-close {
            color: #1c1c1c;
            width: 30px;
            height: 30px;
            line-height: 10px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            svg {
                font-size: 1.1rem;
            }

            &:hover {
                background-color: rgba(0, 0, 0, 0.04);
            }
        }
    }
}