$duotoneA: #36def8; // main (light) tone
$duotoneB: #141445; // main (dark) tone
$bg: #a1a1fb;
$fg: #DDDDEF;

.galery {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    padding: 0px 30px 10px;
    overflow-y: auto;

    &-album-main {
        cursor: pointer;
        margin: 0 2rem 0 0;
        width: fit-content;
        // width: 100%;
        max-width: 100%;
        box-shadow: .25rem .25rem 0 $bg, .5rem .5rem 0 $duotoneA;
        height: fit-content;
        position: relative;

        &-caption {
            position: absolute;
            bottom: 20px;
            left: 5px;
            z-index: 10;
            color: white;
            font-size: 2rem;
            font-family: 'Courier New', Courier, monospace;
            background-color: rgba(0, 0, 0, .3);

            @media(max-width: 1300px) {
                font-size: 1.25rem;
            }
        }

        .img-duotoner {
            display: inline-block;
            // background-color: $duotoneA;
            max-height: inherit;
            max-width: inherit;
            position: relative;

            &:after {
                // we drop the opacity of the dark color so the image is a bit clearer
                background-color: rgba($duotoneB, .5);
                mix-blend-mode: lighten;
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 100%;
            }

            >img {
                // mix-blend-mode: darken;
                max-height: inherit;
                max-width: inherit;
                width: auto;
                position: relative;
                display: inline-block;
            }
        }
    }

    @media(max-width: 1300px) {
        height: calc(var(--vh, 1vh) * 90);
        width: 100vw;
        margin: 5%;
        grid-template-columns: 1fr 1fr;
        overflow-x: hidden;
    }
}