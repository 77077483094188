.main-wrapper {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: 100%;
    align-items: center;

    @media(max-width: 1300px) {
        padding: 0 4%;
        height: calc(var(--vh, 1vh) * 100);
    }
}

.main-page {
    width: 100%;
    display: grid;
    gap: 20px;
    overflow: hidden;
    padding: 3% 5%;
}

.main-page p {
    font-size: 24px;
    text-align: center;
    z-index: 10;
    background: none;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;

    @media(max-width: 1300px) {
        font-size: 18px;
    }
}