@import url('https://fonts.googleapis.com/css?family=Damion|Muli:400,600');

$input-width: 200px;

:focus {
    outline: none;
}

.input-effect {
    position: relative;
    float: left;
}

/* necessary to give position: relative to parent. */
input[type="text"] {
    font: 15px/24px "Lato", Arial, sans-serif;
    color: #333;
    width: 100%;
    box-sizing: border-box;
    letter-spacing: 1px;
}


.effect-20 {
    border: 1px solid #ccc;
    padding: 7px 14px;
    transition: 0.4s;
    background: transparent;
}


.effect-20~.focus-border:before,
.effect-20~.focus-border:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #4caf50;
    transition: 0.3s;
}

.effect-20~.focus-border:after {
    top: auto;
    bottom: 0;
    left: auto;
    right: 0;
}

.effect-20~.focus-border i:before,
.effect-20~.focus-border i:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 0;
    background-color: #4caf50;
    transition: 0.4s;
}

.effect-20~.focus-border i:after {
    left: auto;
    right: 0;
    top: auto;
    bottom: 0;
}

.effect-20:focus~.focus-border:before,
.effect-20:focus~.focus-border:after,
.has-content.effect-20~.focus-border:before,
.has-content.effect-20~.focus-border:after {
    width: 100%;
    transition: 1.3s;
}

.effect-20:focus~.focus-border i:before,
.effect-20:focus~.focus-border i:after,
.has-content.effect-20~.focus-border i:before,
.has-content.effect-20~.focus-border i:after {
    height: 100%;
    transition: 1.4s;
}

.effect-20~label {
    position: absolute;
    left: 0;
    width: calc(100% - 28px);
    top: -18px;
    color: #81c883;
    transition: 0.3s;
    font-size: 12px;
    z-index: 0;
    letter-spacing: 0.5px;
    text-align: left;
    cursor: text;
}
