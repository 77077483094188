.about {
    // display: flex;
    // gap: 20px;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: grid;
    grid-template-columns: 49% 49%;
    grid-template-rows: 100%;
    grid-gap: 2%;
    padding: 50px;

    .bold {
        font-weight: bold;
    }

    @media(max-width: 1300px) {
        grid-template-columns: 100%;
        grid-template-rows: 40% auto;
        height: calc(var(--vh, 1vh) * 100);
        padding: 5%;
    }

    

    &-text {
        font-size: 1.25rem;
        // overflow-y: auto;
        text-indent: 20px;
        margin-bottom: 30px;
        overflow: auto;
        @media(max-width: 1300px) {
            font-size: 1rem;
        }

        &::-webkit-scrollbar {
            width: 7px;
        }
    
        &::-webkit-scrollbar-track {
            background: var(--scrollbarBgColor);
            border-radius: var(--scrollbarBorderRadius);
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: var(--scrollbarThumbColor);
            border-radius: var(--scrollbarBorderRadius);
            border: var(--scrollbarBorder);
            background-image: linear-gradient(180deg,
                    var(--scrollbarThumbColor) 0%,
                    var(--scrollbarThumbColorSecond) 90%);
        }
    }

    &-images {
        height: 100%;
        display: flex;
        flex-direction: column;

        gap: 10px;
        &-main {
            width: 100%;
            height: 100%;
            object-fit: cover;
            
            border-radius: 20px;
            border: 3px solid white;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

        }

        &-list {
            display: flex;
            // grid-template-columns: 1fr 1fr;
            flex-wrap: wrap;
            gap: 10px;
            width: 100%;
            // height: 100%;
            height: 0px;

            

            @media(max-width: 1300px) {
                // flex-direction: column;
            }
        }


    }


}