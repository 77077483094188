html {
  overflow: hidden;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important
}

.root-component {
  display: grid;
  grid-template-rows: 60px calc(100vh - 60px);
  grid-template-columns: 1fr;
  height: calc(var(--vh, 1vh) * 100);
  background: linear-gradient(90deg, #aea4e3, #d3ffe8);
  justify-content: center;
  padding: 0 10%;
  width: 80vw;

  @media(max-width: 1300px) {
    grid-template-rows: 0px 1fr;
    padding: 0;
    width: 100vw;
  }

  &-content {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 40px 0;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100 - 140px);

    @media (max-width: 1300px) {
      height: calc(var(--vh, 1vh) * 100);
      padding: 2% 0 !important;

    }
  }
}